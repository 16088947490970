export default function Contacts() {
  return (
    <div className="page page--contact">
      <div className="container">

        <div className="page-section page-section--contact">
          <div className="h2">Contact</div>
          <div className="contact-body">
            <p>Have a question about pricing or availability and want to contact us?</p>
            <ul className="contact-list">
              <li>
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>email-check</title><path d="M13 19C13 15.69 15.69 13 19 13C20.1 13 21.12 13.3 22 13.81V6C22 4.89 21.1 4 20 4H4C2.89 4 2 4.89 2 6V18C2 19.11 2.9 20 4 20H13.09C13.04 19.67 13 19.34 13 19M4 8V6L12 11L20 6V8L12 13L4 8M17.75 22.16L15 19.16L16.16 18L17.75 19.59L21.34 16L22.5 17.41L17.75 22.16" /></svg>
                  Email:
                </span>
                <b>stefanovdian87@gmail.com</b>
              </li>
              <li>
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>phone</title><path d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z" /></svg>
                  Phone:
                </span>
                <b>+44 7565820600</b>
                {/* <b>+44 7514591947</b> */}
              </li>
              <li>
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>mailbox</title><path d="M17,4H7A5,5 0 0,0 2,9V20H20A2,2 0 0,0 22,18V9A5,5 0 0,0 17,4M10,18H4V9A3,3 0 0,1 7,6A3,3 0 0,1 10,9V18M19,15H17V13H13V11H19V15M9,11H5V9H9V11Z" /></svg>
                  Mailing Address:
                </span>
                <b>13 Cockersand Drive LA1 4QL</b>
              </li>
            </ul>
          </div>
        </div>

        <div className="page-section page-section--location">
          <div className="h2">Location</div>
          <div className="location-body">
            <iframe
              title="title2"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2343.653719588039!2d-2.793081034060661!3d54.02666833123485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487b62f444431d5f%3A0xaa63572c7c2689c5!2sHala%20Square!5e0!3m2!1sen!2suk!4v1657491611697!5m2!1sen!2suk"
              style={{ border: "2px solid gray", minHeight: 500 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              width="100%"
              height="80%"
            />
          </div>
        </div>

      </div>
    </div>

    // <>
    //   <h1>Contact Us</h1><br />
    //   Have a question about pricing or availability and want to contact us? <br />
    //   <hr />
    //   <div className="flexbox-container" style={{ display: "flex" }}>
    //     <div className="sidebar" style={{ flex: 1 }}>
    //       <h1>Email:</h1><br /> stefanovdian87@gmail.com<br />
    //       <h1>Mailing Address:</h1><br /> 13 Cockersand Drive LA1 4QL<br />
    //       <h1>Phone:</h1><br /> +44 7514591947<br />
    //       <h1>After Hours phone:</h1><br /> +44 7514591947<br />
    //     </div>
    //     <div className="main" style={{ flex: 1 }}>

    //       <h1>Location:</h1>
    //       <iframe
    //         title="title2"
    //         src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2343.653719588039!2d-2.793081034060661!3d54.02666833123485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487b62f444431d5f%3A0xaa63572c7c2689c5!2sHala%20Square!5e0!3m2!1sen!2suk!4v1657491611697!5m2!1sen!2suk"
    //         style={{ border: "2px solid gray", minHeight: 500 }}
    //         allowFullScreen
    //         loading="lazy"
    //         referrerPolicy="no-referrer-when-downgrade"
    //         width="100%"
    //         height="80%"
    //       />

    //     </div>
    //   </div>
    // </>
  )
}
