// import SimpleImageSlider from "react-simple-image-slider";

import AwesomeSlider from 'react-awesome-slider';
import '../styles.css';

// New pics gallery;
// https://imgur.com/a/7gdLOi1

const images1 = [
  { url: "https://i.imgur.com/qzwMCR9.jpeg" },
  { url: "https://i.imgur.com/AlCtTHr.jpeg" },
  { url: "https://i.imgur.com/134Izpk.jpeg" },
  { url: "https://i.imgur.com/nJgdtwM.jpeg" },
  { url: "https://i.imgur.com/cb0p4hD.jpeg" },
  { url: "https://i.imgur.com/5V30FYg.jpeg" },
  
];

const images2 = [
  { url: "https://i.imgur.com/eWzbLoq.jpeg" },
  { url: "https://i.imgur.com/KktLVSS.jpeg" },
  { url: "https://i.imgur.com/Qxr5gAI.jpeg" },
  { url: "https://i.imgur.com/5M2w8rD.jpeg" },
];

const images3 = [
  { url: "https://i.imgur.com/2R1tA2l.jpeg" },
  { url: "https://i.imgur.com/nGizLGd.jpeg" },
  { url: "https://i.imgur.com/mrDyQHV.jpeg" },
  { url: "https://i.imgur.com/Ar2J6nQ.jpeg" },
  { url: "https://i.imgur.com/QrriXd0.jpeg" },
];

export default function Gallery() {
  return (
    <div className="page page--gallery">
      <div className="container">

      <section className="page-section page-section--gallery--bathrooms2">
          <div className="h2">Kitchens</div>
          <div className="carousel">
            <AwesomeSlider
              media={[
                {
                  source: images3[0].url,
                },
                {
                  source: images3[1].url,
                },
                {
                  source: images3[2].url,
                },
                {
                  source: images3[3].url,
                },
                {
                  source: images3[4].url,
                },
              ]}
            />
          </div>
        </section>

        <section className="page-section page-section--gallery--bathrooms">
          <div className="h2">Bathrooms</div>
          <div className="carousel">
            <AwesomeSlider
              media={[
                {
                  source: images1[0].url,
                },
                {
                  source: images1[1].url,
                },
                {
                  source: images1[2].url,
                },
                {
                  source: images1[3].url,
                },
              ]}
            />
          </div>
        </section>

        <section className="page-section page-section--gallery--bathrooms2">
          <div className="h2">Heating, Indoor work and Windows</div>
          <div className="carousel">
            <AwesomeSlider
              media={[
                {
                  source: images2[0].url,
                },
                {
                  source: images2[1].url,
                },
                {
                  source: images2[2].url,
                },
                {
                  source: images2[3].url,
                },
              ]}
            />
          </div>
        </section>



      </div>
    </div>
  )
}
