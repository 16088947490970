export default function Services() {
    return (
        <div className="page page--services">
            <div className="container">

                <div className="page-section page-section--services-repair">
                    <div className="h2">Repair Services</div>
                    <div className="page-section__body">
                        <div className="services-list--container">
                            <ul className="services-list">
                                {
                                    repairServices.map((service, index) => (
                                        <li className="service" key={index}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>toolbox</title><path d="M18 16H16V15H8V16H6V15H2V20H22V15H18V16M20 8H17V6C17 4.9 16.1 4 15 4H9C7.9 4 7 4.9 7 6V8H4C2.9 8 2 8.9 2 10V14H6V12H8V14H16V12H18V14H22V10C22 8.9 21.1 8 20 8M15 8H9V6H15V8Z" /></svg>
                                            <span>{service.name}</span>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="page-section page-section--services-repair">
                    <div className="h2">Installation Services</div>
                    <div className="page-section__body">
                        <div className="serices-list--container">
                            <ul className="services-list">
                                {
                                    installationServices.map((service, index) => (
                                        <li className="service" key={index}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>tools</title><path d="M21.71 20.29L20.29 21.71A1 1 0 0 1 18.88 21.71L7 9.85A3.81 3.81 0 0 1 6 10A4 4 0 0 1 2.22 4.7L4.76 7.24L5.29 6.71L6.71 5.29L7.24 4.76L4.7 2.22A4 4 0 0 1 10 6A3.81 3.81 0 0 1 9.85 7L21.71 18.88A1 1 0 0 1 21.71 20.29M2.29 18.88A1 1 0 0 0 2.29 20.29L3.71 21.71A1 1 0 0 0 5.12 21.71L10.59 16.25L7.76 13.42M20 2L16 4V6L13.83 8.17L15.83 10.17L18 8H20L22 4Z" /></svg>
                                            <span>{service.name}</span>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="page-section page-section--services-repair">
                    <div className="h2">Other Services</div>
                    <div className="page-section__body">
                        <div className="services-list--container">
                            <ul className="services-list">
                                {
                                    otherServices.map((service, index) => (
                                        <li className="service" key={index}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>account-wrench-outline</title><path d="M22.9 21.2L18.8 17.1C19.2 16.1 19 14.8 18.1 14C17.2 13.1 15.9 12.9 14.8 13.4L16.7 15.3L15.3 16.7L13.3 14.7C12.8 15.8 13 17.1 13.9 18.1C14.8 19 16 19.2 17 18.8L21.1 22.9C21.3 23.1 21.6 23.1 21.7 22.9L22.7 21.9C23 21.6 23 21.3 22.9 21.2M10 12C12.2 12 14 10.2 14 8S12.2 4 10 4 6 5.8 6 8 7.8 12 10 12M10 6C11.1 6 12 6.9 12 8S11.1 10 10 10 8 9.1 8 8 8.9 6 10 6M13 20H2V17C2 14.3 7.3 13 10 13C10.5 13 11.2 13.1 11.9 13.2C11.5 13.7 11.3 14.3 11.1 15C10.7 15 10.4 14.9 10 14.9C7 14.9 3.9 16.4 3.9 17V18.1H11.5C11.8 18.9 12.4 19.5 13 20Z" /></svg>
                                            <span>{service.name}</span>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

const repairServices = [
    {
        name: "Bathroom repairs",
    },
    {
        name: "Plumbing repairs",
    },
    {
        name: "Radiator repairs",
    },
    {
        name: "Central heating repairs",
    },
    {
        name: "Window replacements",
    },

]

const installationServices = [
    {
        name: "Sink installation",
    },
    {
        name: "Toilet installation",
    },
    {
        name: "Central heating installation",
    },
    {
        name: "Door and window installation",
    }
]

const otherServices = [
    {
        name: "Safety checks",
    },
    {
        name: "24-hour emergency service",
    },
    {
        name: "Waste pipe unblocking",
    },
    {
        name: "Burst pipe repair",
    },
    {
        name: "Fast response",
    },
    {
        name: "Calls are free of charge",
    },
]