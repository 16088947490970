export default function Sidebar() {
  return (
    <>
      <div className="hideScroll">
        <nav className="nav-bar">
          <div className="nav-wrapper">
            <ul className="nav-list">
              <li className="nav-list-item">
                <a href="/" className="nav-link">
                  Home
                </a>
              </li>
              <li className="nav-list-item">
                <a href="/gallery" className="nav-link">
                  Gallery
                </a>
              </li>
              <li className="nav-list-item">
                <a href="/contact" className="nav-link">
                  Contact
                </a>
              </li>
              <li className="nav-list-item">
                <a href="/servicearea" className="nav-link">
                  Area
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>

    </>
  )
}
