import React, { useState } from 'react';

export default function QNA () {
    const [activeQNA, handleActiveQNA] = useState('');

    return (
        <div className="page page--qna">
            <div className="container">
                <div className="page-section page-section--qna">
                    <div className="h2">Frequently Asked Questions</div>
                    <div className="page-section__body">
                        {qnaList.map((qna, index) => (
                            <div 
                                key={index}
                                className={activeQNA !== qna.question ? 'qna-el' : 'qna-el qna-el--active'} 
                                onClick={() => activeQNA !== qna.question ? handleActiveQNA(qna.question) : handleActiveQNA('')}
                            >
                                <div className="qna-el__question">
                                    <span>{qna.question}</span>
                                    <svg className="qna-el__question__chevron" width="20px" height="20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>chevron-down</title><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
                                </div>
                                <div className="qna-el__answer">
                                    <span>{qna.answer}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

const qnaList = [
    {
        question: "Can I receive a free quote or estimate over the phone?",
        answer: "Yes, we can provide a preliminary estimate. We will also notify you of any additional problems that have arisen during the initial inspection of the site."
    },
    {
        question: "How long will it take to get the job done?",
        answer: "The timeline for completing the task depends on the specific job requirements. Our experienced technicians can provide an estimated timeframe once an on-site assessment is conducted.",
    },
    {
        question: "I cannot take a day off work. Is it possible for you to come after 17:00?",
        answer: "Yes, we do work after 17:00. Our flexible scheduling options aim to provide convenient solutions for our customers' heating and plumbing needs."
    }
]