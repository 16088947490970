import { Route, BrowserRouter, Routes } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import Sidebar from './components/Sidebar'
import Home from './components/Home'
import Gallery from "./components/Gallery"
import Contacts from "./components/Contacts"
import Area from "./components/Area"
import QNA from "./components/QnA"
import Services from "./components/Services"
import React from "react"

export function Pathing() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contacts />} />
          <Route path="/services" element={<Services />} />
          <Route path="/qna" element={<QNA />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/servicearea" element={<Area />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export function Filling() {
  return (
    <>
      <Header />
      <div className="png-page">
        <Sidebar />
        <Pathing />
      </div>
      <Footer />
    </>
  )
}
