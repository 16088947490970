import React, { useState, useEffect } from 'react';

export default function Home () {
  const [selectedSection, handleSelectedSection] = useState('');

  useEffect(() => {
    handleServiceIcon();
  })

  function handleSection (serviceName) {
    if (serviceName === selectedSection){
      handleSelectedSection('');
    } else {
      handleSelectedSection(serviceName);
    }
  }

  function handleServiceIcon(){
    const serviceEls = document.querySelectorAll(".service");
    if (serviceEls.length > 0){
      serviceEls.forEach((serviceEl, index) => {
        const serviceElName = serviceEl.querySelector('.service__name').textContent;
        const serviceIconEl = serviceEl.querySelector('.service__icon');
        let serviceIcon;
        if (serviceElName === services[index].serviceName){
          serviceIcon = services[index].serviceIcon;
          serviceIconEl.innerHTML = serviceIcon;
        }
      })
    }
  }

  return (
    <div className="page page--home">
      <div className="container">
        <section className="page-section page-section--introduction">
          <div className="h2">Evolution - Plumbing and Heating</div>
          <p>
            Welcome to EvolutionPHG, your trusted provider of heating and plumbing services in Lancaster, 
            Lancashire, UK. Our team of certified professionals offers a wide range of services, including radiator 
            changes, maintenance, fireplace maintenance, pipe replacements, sink replacements, toilet 
            maintenance, and landlord safety checks. In addition, we specialize in expert window and door installation, 
            ensuring your home remains secure, energy-efficient, and aesthetically pleasing. Trust EvolutionPHG for all your heating, plumbing, and home improvement needs.
          </p>
        </section>
      </div>
      <div className="container container--wide">
        <section className="page-section page-section--services">
          <div className="services-container">
            {
              services.map(service => (
                <div 
                  className={selectedSection === service.serviceName ? 'service service--active' : 'service'} 
                  key={service.serviceName} 
                  onClick={() => handleSection(service.serviceName)}
                >
                  <div className="service__top">
                    <div className="service__icon"></div>
                    <div className="service__name">
                      {service.serviceName}
                      <svg className="service__chevron" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
                    </div>
                  </div>
                  <div className="service__description">{service.serviceDescription}</div>
                  
                </div>
              ))
            }
          </div>
        </section>
      </div>
      <div className="container">
        <section className="page-section page-section--contact">
          <div className="h2">Need our help?</div>
          <div className="contact-list-container">
            <ul className="contact-list">
              <li>
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>email-check</title><path d="M13 19C13 15.69 15.69 13 19 13C20.1 13 21.12 13.3 22 13.81V6C22 4.89 21.1 4 20 4H4C2.89 4 2 4.89 2 6V18C2 19.11 2.9 20 4 20H13.09C13.04 19.67 13 19.34 13 19M4 8V6L12 11L20 6V8L12 13L4 8M17.75 22.16L15 19.16L16.16 18L17.75 19.59L21.34 16L22.5 17.41L17.75 22.16" /></svg>
                  Email:
                </span>
                <b>stefanovdian87@gmail.com</b>
              </li>
              <li>
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>phone</title><path d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z" /></svg>
                  Phone:
                </span>
                {/* <b>+44 7514591947</b> */}
                <b>+44 7565820600</b>
              </li>
              <li>
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>mailbox</title><path d="M17,4H7A5,5 0 0,0 2,9V20H20A2,2 0 0,0 22,18V9A5,5 0 0,0 17,4M10,18H4V9A3,3 0 0,1 7,6A3,3 0 0,1 10,9V18M19,15H17V13H13V11H19V15M9,11H5V9H9V11Z" /></svg>
                  Mailing Address:
                </span>
                <b>13 Cockersand Drive LA1 4QL</b>
              </li>
            </ul>
          </div>
        </section>
        <section className="page-section page-section--work-hours">
          <div className="h2">Work Hours</div>
          <p>
            We believe that quality work in the hands of a professional can be a pleasant experience and we strive for the best customer service. We area based in Lancashire, but we cover: Lancaser, Preston, Kendal, Blackpool, Morecambe, Liverpool and Manchester and we are available 24/7 for any and all emergencies. Expect a response on email in less than 12 hours. Normal working hours are:
          </p>
          <div className="work-hours-list-container">
            <ul className="work-hours-list">
              <li>
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>calendar-weekend-outline</title><path d="M19 3C20.11 3 21 3.9 21 5V19C21 20.11 20.11 21 19 21H5C3.89 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3H6V1H8V3H16V1H18V3H19M19 19V9H5V19H19M19 7V5H5V7H19M7 11H9V17H7V11M15 11H17V17H15V11Z" /></svg>
                  Monday-Friday
                </span>
                <b>8:00-20:00</b>
              </li>
              <li>
                <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>calendar-weekend-outline</title><path d="M19 3C20.11 3 21 3.9 21 5V19C21 20.11 20.11 21 19 21H5C3.89 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3H6V1H8V3H16V1H18V3H19M19 19V9H5V19H19M19 7V5H5V7H19M7 11H9V17H7V11M15 11H17V17H15V11Z" /></svg>
                  Saturday
                </span>
                <b>9:00-14:00</b>
              </li>
            </ul>
          </div>
          <p>
            Emergency services outside of working hours will be charged at a premium rate.
          </p>
        </section>
      </div>
      <div className="container container--wide">
        <section className="page-section page-section--reviews">
          <div className="h2">Reviews</div>
          <div className="reviews-container">
            {
              reviews.map((review, index) => (
                <div className="review" key={index}>
                  <div className="review-name">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>message-draw</title><path d="M18,14H10.5L12.5,12H18M6,14V11.5L12.88,4.64C13.07,4.45 13.39,4.45 13.59,4.64L15.35,6.41C15.55,6.61 15.55,6.92 15.35,7.12L8.47,14M20,2H4A2,2 0 0,0 2,4V22L6,18H20A2,2 0 0,0 22,16V4C22,2.89 21.1,2 20,2Z" /></svg>
                    {review.name}
                  </div>
                  <div className="review-main">{review.desc}</div>
                </div>
              ))
            }
          </div>
          <div className="reviews-additional">
            <a href="https://www.google.com/search?q=evolution+phg&oq=evolution+phg&aqs=chrome.0.69i59l2j69i61l3.3991j0j15&sourceid=chrome&ie=UTF-8#lrd=0xa70bdd202ee18e81:0xe510d9db8a6bf074,1,,,," className="reviews-additional-link" target="_blank" rel="noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>plus-thick</title><path d="M20 14H14V20H10V14H4V10H10V4H14V10H20V14Z" /></svg>
              <span>Browse More Reviews or Share Your Own Experience</span>
            </a>
          </div>
        </section>
      </div>
    </div>
  )
}

const reviews = [
  {
    name: "Ruth James",
    desc: "Install very fast and efficient service.  Would highly recommend",
  },
  {
    name: "Carly Heron",
    desc: "Brilliant service. Very fast and reliable. Very reasonable priced. Would highly recommend!",
  },
  {
    name: "Richard Thompson",
    desc: "Brilliant service and great value for money. Installed new heater, Dido fixed the problem within an afternoon. Looked around, competitive pricing, best price. "
  }
]

const services = [
  {
    serviceIcon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22,22H2V20H22V22M22,6H2V3H22V6M20,7V19H17V11C17,11 14.5,10 12,10C9.5,10 7,11 7,11V19H4V7H20M14.5,14.67H14.47L14.81,15.22L14.87,15.34C15.29,16.35 15,17.5 14.21,18.24C13.5,18.9 12.5,19.07 11.58,18.95C10.71,18.84 9.9,18.29 9.45,17.53C9.3,17.3 9.19,17.03 9.13,16.77L9,16.11C8.96,15.15 9.34,14.14 10.06,13.54C9.73,14.26 9.81,15.16 10.3,15.79L10.36,15.87C10.44,15.94 10.55,15.97 10.64,15.92C10.73,15.89 10.8,15.8 10.8,15.7L10.76,15.56C10.23,14.17 10.68,12.55 11.79,11.63C12.1,11.38 12.5,11.15 12.87,11.05C12.46,11.87 12.61,12.93 13.25,13.57L14.14,14.3L14.5,14.67M13.11,17.44V17.44C13.37,17.2 13.53,16.8 13.5,16.44V16.25C13.38,15.65 12.85,15.46 12.5,15L12.26,14.55C12.13,14.85 12.12,15.13 12.17,15.46C12.23,15.8 12.37,16.09 12.29,16.44C12.2,16.83 11.9,17.22 11.37,17.35C11.67,17.64 12.15,17.87 12.64,17.71L13.11,17.44Z" /></svg>`,
    serviceName: "Fireplace Maintenance",
    serviceDescription: "Is your fireplace in need of maintenance or repair? Look no further than EvolutionPHG. We offer comprehensive fireplace maintenance services, from cleaning and repairs to installation of new systems. We can help you keep your home warm and cozy all winter long."
  },
  {
    serviceIcon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="presentation"><path d="M21 20V2H3V20H1V23H23V20M19 4V11H17V4M5 4H7V11H5M5 20V13H7V20M9 20V4H15V20M17 20V13H19V20Z" style="fill:#FD4B40"></path></svg>`,
    serviceName: "Door and Window Installation",
    serviceDescription: "Looking to upgrade your windows and doors? EvolutionPHG offers expert installation services to enhance the security, energy efficiency, and aesthetic appeal of your home. Our team is skilled in installing a variety of window and door types to meet your specific needs and preferences."
  },
  {
    serviceIcon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7.95,3L6.53,5.19L7.95,7.4H7.94L5.95,10.5L4.22,9.6L5.64,7.39L4.22,5.19L6.22,2.09L7.95,3M13.95,2.89L12.53,5.1L13.95,7.3L13.94,7.31L11.95,10.4L10.22,9.5L11.64,7.3L10.22,5.1L12.22,2L13.95,2.89M20,2.89L18.56,5.1L20,7.3V7.31L18,10.4L16.25,9.5L17.67,7.3L16.25,5.1L18.25,2L20,2.89M2,22V14A2,2 0 0,1 4,12H20A2,2 0 0,1 22,14V22H20V20H4V22H2M6,14A1,1 0 0,0 5,15V17A1,1 0 0,0 6,18A1,1 0 0,0 7,17V15A1,1 0 0,0 6,14M10,14A1,1 0 0,0 9,15V17A1,1 0 0,0 10,18A1,1 0 0,0 11,17V15A1,1 0 0,0 10,14M14,14A1,1 0 0,0 13,15V17A1,1 0 0,0 14,18A1,1 0 0,0 15,17V15A1,1 0 0,0 14,14M18,14A1,1 0 0,0 17,15V17A1,1 0 0,0 18,18A1,1 0 0,0 19,17V15A1,1 0 0,0 18,14Z" /></svg>`,
    serviceName: "Radiator and Pipe Replacements",
    serviceDescription: "At EvolutionPHG, we understand the importance of having reliable and efficient heating systems in your home or business. Our team can help you upgrade your radiators and pipes, ensuring that your system is running at peak efficiency and saving you money on energy bills."
  },
  {
    serviceIcon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 14V16H16V14.28L19 14M19 13C19 11.9 18 11 16.8 11H10V10H5V21H10V13.91L19 13M5 9H10V7L15.36 5.21C15.74 5.09 16 4.73 16 4.33C16 3.68 15.36 3.23 14.75 3.45L5 7V9Z" /></svg>`,
    serviceName: "Property maintenance",
    serviceDescription: "At EvolutionPHG, we specialize in sink and toilet repair and maintenance. Our skilled technicians have years of experience in fixing and replacing sinks and toilets efficiently and effectively. From minor repairs to complete replacements, we have got you covered. Contact us today for all your sink and toilet maintenance needs.",
  },
  {
    serviceIcon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10 4A4 4 0 0 1 14 8A4 4 0 0 1 10 12A4 4 0 0 1 6 8A4 4 0 0 1 10 4M10 14C14.42 14 18 15.79 18 18V20H2V18C2 15.79 5.58 14 10 14M20 12V7H22V13H20M20 17V15H22V17H20Z" /></svg>`,
    serviceName: "Safety Checks",
    serviceDescription: "We understand the importance of keeping your tenants safe, and our team provides comprehensive landlord safety checks to ensure that your property meets all necessary safety standards. We can provide heating checks, house integrity inspection, and more to help you keep your tenants safe and secure."
  }
]

